import { FieldArray, useFormikContext } from 'formik'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FormAddAnotherButton } from './FormAddAnotherButton'
import { FormDeleteFieldButton } from './FormDeleteFieldButton'
import { SearchableSelect } from './SearchableSelect'
import { AuthContext, ROLE } from 'src/contexts/authContext'
import { FormTextInput } from 'src/components/forms/FormTextInput'
import { CFormInput } from '@coreui/react-pro'

export default function FormSelectMultiplePartners({
  partnerOptions,
  manufacturerOptions,
  readOnly,
  allBranches,
  setInitialvaluesChanged,
  mfSettings,
}) {
  const { t } = useTranslation()
  const auth = useContext(AuthContext)

  partnerOptions = partnerOptions?.map((partner) => {
    return { label: partner.name, value: partner.id }
  })
  manufacturerOptions = manufacturerOptions.map((partner) => {
    return { label: partner.name, value: partner.id }
  })
  const {
    values: { partners, branches, mfPartners },
    setFieldValue,
  } = useFormikContext()
  let partner_count = 0
  let manufacturers_count = 0

  const getBranchOptions = (partnerId) => {
    let selectedPartner = partnerOptions.filter((partner) => partner.value == partnerId)[0]
    let branches = []
    if (!selectedPartner) {
      let parent_id = allBranches.find((branch) => branch.id == partnerId).parent_id
      selectedPartner = partnerOptions.find((partner) => partner.value == parent_id)
      branches = allBranches.filter((branch) => branch.parent_id === parent_id)
    } else {
      branches = allBranches.filter((branch) => branch.parent_id === partnerId)
    }
    branches = branches.map((branch) => {
      return { label: branch?.name, value: branch?.id }
    })
    branches.unshift({ label: selectedPartner.label + ' (HQ)', value: selectedPartner.value })
    return branches
  }

  const findErpProjectId = (mfId) => {
    const setting = mfSettings.find((erpOption) => erpOption.mf_id === mfId)
    return setting ? setting.erp_project_id : null
  }

  const findErpProjectName = (mfId) => {
    const setting = mfSettings.find((erpOption) => erpOption.mf_id === mfId)
    return setting ? setting.erp_project_name : null
  }

  return (
    <>
      {(auth.role === ROLE.CUBOTOO_ADMIN || auth.role === ROLE.BUILDING_CONTRACTOR_ADMIN) && (
        <FieldArray
          name={'partners'}
          render={({ push, remove }) => (
            <>
              {!!partnerOptions.length &&
                partners.map((partner, index) => {
                  const options = partnerOptions
                  const hasPartnerOptions = options.map((pr) => pr.value).includes(partner)
                  partner_count =
                    partner === ''
                      ? partner_count + 1
                      : hasPartnerOptions
                      ? partner_count + 1
                      : partner_count
                  return (
                    <>
                      {((!readOnly && partner === '') || hasPartnerOptions) && (
                        <div key={index} className="w-2 h-2 position-relative">
                          <p className="spartan-font">
                            {partners.length === 1
                              ? t('Backoffice.Wizard.AddPartner.Type.BC')
                              : `${t('Backoffice.Wizard.AddPartner.Type.BC')} (${partner_count})`}
                          </p>
                          <SearchableSelect
                            className="my-2 form-control-backoffice"
                            placeholder={t('Backoffice.Wizard.AddPartner.Type.BC')}
                            name={`partners.${index}`}
                            id={`partners.${index}`}
                            options={options}
                            disabled={readOnly}
                          />
                          {partner && getBranchOptions(partner).length > 0 && (
                            <SearchableSelect
                              className="my-2 form-control-backoffice"
                              placeholder={t('Backoffice.branches.table.columns.branch')}
                              name={`branches.${index}`}
                              id={`branches.${index}`}
                              options={getBranchOptions(partner)}
                              disabled={readOnly}
                            />
                          )}
                          {!readOnly && partners.length > 1 && (
                            <FormDeleteFieldButton
                              index={index}
                              handleClick={() => {
                                remove(index)
                                const updatedBranches = [...branches]
                                updatedBranches.splice(index, 1)
                                setFieldValue('branches', updatedBranches)
                                setInitialvaluesChanged(true)
                              }}
                            />
                          )}
                        </div>
                      )}
                    </>
                  )
                })}
              {!readOnly && <FormAddAnotherButton push={push} values={''} />}
            </>
          )}
        />
      )}
      {(auth.role === ROLE.CUBOTOO_ADMIN || auth.role === ROLE.MANUFACTURER_ADMIN) && (
        <FieldArray
          name={'mfPartners'}
          render={({ push, remove }) => (
            <>
              {!!manufacturerOptions.length &&
                mfPartners.map((mfPartner, idx) => {
                  const options = manufacturerOptions
                  const hasMfPartnerOptions = options.map((pr) => pr.value).includes(mfPartner)
                  manufacturers_count =
                    mfPartner === ''
                      ? manufacturers_count + 1
                      : hasMfPartnerOptions
                      ? manufacturers_count + 1
                      : manufacturers_count
                  return (
                    <>
                      {((!readOnly && mfPartner === '') || hasMfPartnerOptions) && (
                        <div key={idx} className="w-2 h-2 position-relative">
                          <p className="spartan-font">
                            {mfPartners.length === 1
                              ? t('Backoffice.Wizard.AddPartner.Type.MF')
                              : `${t(
                                  'Backoffice.Wizard.AddPartner.Type.MF',
                                )} (${manufacturers_count})`}
                          </p>
                          <SearchableSelect
                            className="my-2 form-control-backoffice"
                            placeholder={t('Backoffice.Wizard.AddPartner.Type.MF')}
                            name={`mfPartners.${idx}`}
                            id={`mfPartners.${idx}`}
                            options={options}
                            disabled={readOnly}
                          />
                          {!readOnly && mfPartners.length > 1 && (
                            <FormDeleteFieldButton
                              index={idx}
                              handleClick={() => {
                                remove(idx)
                                const updatedMfPartners = [...mfPartners]
                                updatedMfPartners.splice(idx, 1)
                                setFieldValue('mfPartners', updatedMfPartners)
                                setInitialvaluesChanged(true)
                              }}
                            />
                          )}
                          {mfSettings && mfSettings.length > 0 && findErpProjectId(mfPartner) && (
                            <div>
                              <p className="w-2 h-2 position-relative">
                                <CFormInput
                                  floatingLabel={'ERP Project ID'}
                                  type={'text'}
                                  disabled={true}
                                  id={`mfErpId.${idx}`}
                                  placeholder={'ERP Project ID'}
                                  name={`mfErpId.${idx}`}
                                  defaultValue={findErpProjectId(mfPartner)}
                                />
                              </p>
                              <p className="w-2 h-2 position-relative">
                                <CFormInput
                                  floatingLabel={'ERP Project Name'}
                                  type={'text'}
                                  disabled={true}
                                  id={`mfErpName.${idx}`}
                                  placeholder={'ERP Project Name'}
                                  name={`mfErpName.${idx}`}
                                  defaultValue={findErpProjectName(mfPartner)}
                                />
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )
                })}
            </>
          )}
        />
      )}
    </>
  )
}
