import React, { useContext, useEffect } from 'react'
import jwt from 'jwt-decode'
import { useState } from 'react'
import { AuthContext, ROLE } from './authContext'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { getBranchProjects } from 'src/services/requests/projects'
import { getAllPartners } from 'src/services/requests/partners'
import { getProjectMaterialPreview } from 'src/services/requests/projectMaterialPreview'
import { ProjectMaterialViewContext } from './projectMaterialViewContext'

import { GlobalVariablesContext } from './globalVariablesContext'

export const branch = {
  id: '',
  address: '',
  name: '',
  status: '',
  type: '',
  parent_id: '',
}

export const project = {
  id: '',
  name: '',
  value: '',
}

const defaultUserData = {
  email: '',
  firstName: '',
  secondName: '',
}

export const UserDataContext = React.createContext()

const processProjectsQueryResponse = (projectsResponse) => {
  const projectOptions = projectsResponse.data.map((project) => {
    return { id: project.id, name: project.name, value: project.value, status: project.status }
  })
  return projectOptions
}

const processBranchesQueryResponse = (branchesResponse) => {
  const branchesOptions = branchesResponse.data.map((branch) => {
    return {
      id: branch.id,
      address: `${branch.address.street} ${branch.address.house_nr}; ${branch.address.postal_code}, ${branch.address.city}`,
      name: branch.name,
      status: branch.status,
      type: branch.type,
      parent_id: branch.parent_id,
    }
  })
  return branchesOptions
}

const canFetch = (branch, project) => {
  let decision = true
  decision &= branch && branch.id && branch.id.length > 1 ? true : false //do not fetch if there is no branch
  decision &= project && project.id && project.id.length > 1 ? true : false //do not fetch if there is no project

  return decision
}

export const UserDataProvider = ({ children }) => {
  const [userData, setUserData] = useState(defaultUserData)

  const [selectedProject, _setSelectedProject] = useLocalStorage('selected-project', { ...project })
  const [selectedBranch, _setSelectedBranch] = useLocalStorage('selected-branch', { ...branch })
  const [branches, setBranches] = useState([])
  const [projects, setProjects] = useState([])
  const [branchUpdated, setBranchUpdated] = useState(false)
  const { setPmvsForPreview, setPmvData, setPmvId } = useContext(ProjectMaterialViewContext)
  const { setError } = useContext(GlobalVariablesContext)

  const {
    getSessionInfo,
    role,
    sessionInfo: { idToken },
  } = useContext(AuthContext)

  useEffect(() => {
    if (idToken) {
      const { family_name, given_name, email } = jwt(idToken)
      setUserData({
        firstName: family_name,
        secondName: given_name,
        email,
      })
    }
  }, [idToken])

  useEffect(() => {
    const loadEffect = async () => {
      await getSessionInfo()

      let [resBranches] = await Promise.all([getAllPartners()])

      if (resBranches.data.length)
        resBranches.data = resBranches.data.filter((partner) => partner.status !== 'inactive')

      if (!resBranches?.data.length) {
        setBranches([])
        setSelectedBranch({})
      } else {
        const branchesOptions = processBranchesQueryResponse(resBranches)
        setBranches(branchesOptions)

        if (branchesOptions.length === 1) {
          setSelectedBranch(branchesOptions[0])
        } else if (branchesOptions.length === 0) {
          setSelectedBranch(branch)
        } else {
          setSelectedBranch(
            selectedBranch.id
              ? { ...branchesOptions.find((br) => br.id === selectedBranch.id) }
              : { ...branch },
          )
        }
      }
    }
    if (role !== ROLE.MANUFACTURER_ADMIN && role !== ROLE.MANUFACTURER) {
      loadEffect()
    }
  }, [branchUpdated])

  const setSelectedProject = (project) => {
    _setSelectedProject(project)
  }

  const setSelectedBranch = (branch) => {
    _setSelectedBranch(branch)

    getBranchProjects(branch.id)
      .then((response) => {
        setProjects(processProjectsQueryResponse(response))
      })
      .catch((exception) => {
        setProjects([])
        setError(exception?.response?.data?.error)
      })
  }

  const fetchProjectMaterialPreview = async (branchId, projectId, restrictedForNewItems) => {
    await getSessionInfo()

    return new Promise(function (resolve, reject) {
      getProjectMaterialPreview(branchId, projectId, restrictedForNewItems)
        .then((response) => {
          if (response.data.length > 0) {
            setPmvsForPreview(response.data)
            setPmvId(filterPmv(response.data, projectId).id)
          } else {
            setPmvData(null)
            setPmvsForPreview([])
          }
          resolve(response)
        })
        .catch((exception) => {
          setPmvsForPreview([])
          reject(error)
          setError(exception?.response?.data?.error)
        })
    })
  }

  const filterPmv = (pmvs, projectId) => {
    for (let i = 0; i < pmvs.length; ++i) {
      if (pmvs[i].contextId === projectId) {
        return pmvs[i]
      }
    }
    return {
      id: '',
    }
  }

  return (
    <UserDataContext.Provider
      value={{
        selectedBranch,
        selectedProject,
        setSelectedBranch,
        setSelectedProject,
        fetchProjectMaterialPreview,
        setProjects,
        userData,
        branches,
        projects,
        canFetch,
        setBranchUpdated,
      }}
    >
      {children}
    </UserDataContext.Provider>
  )
}
