import React, { useState } from 'react'
import Upload from 'rc-upload'
import './styles.scss'
import { CButton, CContainer, CRow, CCol, CProgress, CProgressBar } from '@coreui/react-pro'
import { Conditional } from 'src/components/Conditional'
import { useTranslation } from 'react-i18next'

export default function UploadButton({ onResultAvailable = (reader, fileName) => {} }) {
  const [percentage, setPercentage] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [fileName, setFileName] = useState()
  const [fileSize, setFileSize] = useState()
  const { t } = useTranslation()

  const props = {
    accept: '.xls,.xlsx',
    beforeUpload: (file) => {
      setIsUploading(true)
      setFileName(file.name)
      setFileSize(Math.floor(file.size / 1000))

      const reader = new FileReader()

      reader.onloadend = () => {
        onResultAvailable(reader, file.name)
      }
      reader.readAsArrayBuffer(file)
      return false
    },
    onSuccess: () => {
      // Finish upload
      setIsUploading(false)
    },
    onProgress: (step) => {
      // Update progress
      setPercentage(Math.round(step.percent))
    },
  }

  return (
    <CContainer fluid>
      <CRow>
        <CCol className="d-flex justify-content-center mb-2">
          <b>{fileName}</b>
        </CCol>
      </CRow>
      <CRow>
        <CCol className="d-flex justify-content-center ">
          <div className="progress-bar-width">
            <CContainer fluid>
              <CRow>
                <CCol>
                  <Upload {...props}>
                    <Conditional condition={!isUploading || !fileName}>
                      <CButton className="d-flex mx-auto">
                        {t('Backoffice.ProductUpload.Button.Label')}
                      </CButton>
                    </Conditional>
                    <Conditional condition={isUploading || fileName}>
                      <CProgress className="mt-3">
                        <CProgressBar color="warning" value={percentage} />
                      </CProgress>
                    </Conditional>
                  </Upload>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  )
}
