import React, { useEffect, useState } from 'react'
import 'src/views/pages/products/components/styles.scss'

import {
  CContainer,
  CRow,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CModal,
} from '@coreui/react-pro'

import { getAllManufacturerPartners, getAllManufacturers } from 'src/services/requests/partners'
import { filterAny, alphabeticalSort } from 'src/components/table/lib/table-lib.js'
import { Conditional } from 'src/components/Conditional'
import { Spinner } from 'src/components/Spinner'
import { cilSearch } from '@coreui/icons-pro'
import { useTranslation } from 'react-i18next'
import Grid from './components/Grid'
import CIcon from '@coreui/icons-react'
import emptyExcelTemplate from 'src/assets/empty-product-data.xlsx'
import exampleExcelTemplate from 'src/assets/example-product-data.xlsx'

let originalData

export default function ProductAndPriceManagement() {
  const [partners, setPartners] = useState([])
  const [widgetViewWVisible, setWidgetViewVisible] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [branches, setBranches] = useState([])
  const [buildingContractors, setBuildingContractors] = useState([])

  const { t } = useTranslation()

  useEffect(() => {
    getAllManufacturers().then((response) => {
      originalData = response.data.sort(alphabeticalSort('name'))
      originalData = originalData.filter((partner) => partner.status !== 'inactive')
      setPartners(originalData)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    getAllManufacturerPartners()
      .then((response) => {
        setBuildingContractors(response.data.filter((e) => e.parent_id === null))
        setBranches(response.data.filter((e) => e.parent_id !== null))
      })
      .catch((exception) => {
        console.log(exception?.response?.data?.error)
      })
  }, [])

  const handleUserInput = (e) => {
    setPartners(filterAny(originalData, e.target.value))
  }
  return (
    <>
      <Conditional condition={widgetViewWVisible}>
        <Spinner
          message={t('Backoffice.ProductManagement.Label.FetchPartners')}
          pageloading={isLoading}
        >
          <CContainer fluid className="products-container">
            <CRow className="justify-content-between">
              <CCol className="col-auto d-flex align-items-center">
                <div>
                  <CIcon size="xl" icon={cilSearch} className="me-1" />
                </div>
                <div style={{ width: '280px' }}>
                  <CFormInput
                    onChange={handleUserInput}
                    placeholder={t('Backoffice.ProductManagement.Label.Search')}
                  ></CFormInput>
                </div>
              </CCol>
              <CCol className="col-auto d-flex align-items-center mt-4 mt-lg-0">
                <CDropdown>
                  <CDropdownToggle href="#">
                    {t('Backoffice.ProductManagement.Label.Download')}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem href={emptyExcelTemplate}>
                      {t('Backoffice.ProductManagement.Label.Download.EmptyTemplate')}
                    </CDropdownItem>
                    <CDropdownItem href={exampleExcelTemplate}>
                      {' '}
                      {t('Backoffice.ProductManagement.Label.Download.Example')}
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <Grid
                  data={partners}
                  columns={3}
                  branches={branches}
                  buildingContractors={buildingContractors}
                ></Grid>
              </CCol>
            </CRow>
          </CContainer>
        </Spinner>
      </Conditional>
    </>
  )
}
